<template>
  <ClientOnly>
    <Wrapper
      v-if="!isCSGame"
      :class="{
        [$style['withdrawal-wrapper']]: true,
        [$style['b-width-1']]: true,
      }"
    >
      <template #tools><GameSelect /></template>
      <component :is="is" />
    </Wrapper>
    <CSWrapper
      v-else
      :class="{
        [$style['withdrawal-wrapper']]: true,
        [$style['withdrawal-wrapper-cs']]: true,
        [$style['b-width-2']]: true,
      }"
    >
      <template #tools><GameSelect /></template>
      <component :is="is" />
    </CSWrapper>
    <SupportHelp :class="$style['b-width-1']" />
  </ClientOnly>
</template>

<script setup lang="ts">
import type { PlatformName } from "~/types";
import Wrapper from "~/components/Balance/Withdrawal/Wrapper.vue";
import CSWrapper from "~/components/Balance/Withdrawal/CSWrapper.vue";
import GameSelect from "~/components/Balance/Withdrawal/GameSelect.vue";
import SupportHelp from "~/components/Balance/SupportHelp.vue";

const platform = computed(() => usePlatform());

const paymentsStore = usePaymentsStore();

const { withdrawalGame } = storeToRefs(paymentsStore);

const StandoffForm = defineAsyncComponent(
  () => import("~/components/Balance/Withdrawal/StandoffForm.vue"),
);
const GenshinForm = defineAsyncComponent(
  () => import("~/components/Balance/Withdrawal/GenshinForm.vue"),
);
const PubgForm = defineAsyncComponent(
  () => import("~/components/Balance/Withdrawal/PubgForm.vue"),
);

const RobloxForm = defineAsyncComponent(
  () => import("~/components/Balance/Withdrawal/RobloxForm.vue"),
);

const CSForm = defineAsyncComponent(
  () => import("~/components/Balance/Withdrawal/CSForm.vue"),
);

const BgmiForm = defineAsyncComponent(
  () => import("~/components/Balance/Withdrawal/BgmiForm.vue"),
);

const map: Record<PlatformName, any> = {
  standoff: StandoffForm,
  genshin: GenshinForm,
  pubg: PubgForm,
  bgmi: BgmiForm,
  roblox: RobloxForm,
  cs2: CSForm,
};

const is = computed(() => {
  return map[withdrawalGame.value];
});

const isCSGame = computed(() => {
  return withdrawalGame.value === "cs2";
});

watch(
  () => platform.value,
  () =>
    (paymentsStore.withdrawalGame =
      platform.value.title === "BGMI" ? "bgmi" : platform.value.code),
  { immediate: true },
);
</script>

<style lang="scss" module>
.b-width-1 {
  max-width: 506px;
}

.b-width-2 {
  max-width: 1092px;
}

.withdrawal-wrapper {
  &-cs {
    position: relative;
    overflow: hidden;
  }
}
</style>
