<template>
  <BaseSelect
    :selected-text="selectedPlatform?.title"
    data-test="withdrawal-choose-game"
  >
    <template
      v-for="platform in platforms"
      :key="platform.value"
    >
      <Option
        v-if="
          (platform.title != 'PUBGM' && coreStore.userCountry === 'IN') ||
          (platform.title != 'BGMI' && coreStore.userCountry != 'IN')
        "
        :item="platform.title"
        :selected="platform.value === selectedPlatform?.value"
        @update:select="selectPlatform(platform)"
      />
    </template>
  </BaseSelect>
</template>

<script setup lang="ts">
import type { PlatformName } from "~/types";
import BaseSelect from "../BaseSelect/BaseSelect.vue";
import Option from "../BaseSelect/Option.vue";

const paymentsStore = usePaymentsStore();
const platform = usePlatform();
const coreStore = useCoreStore();

type PlatformItemType = { title: any; value: PlatformName };
const platforms = computed<Array<PlatformItemType>>(() => {
  return Object.values(platform.ALL_PLATFORMS_DATA).map((item) => ({
    title: item.title,
    value: item.code,
  }));
});

const selectPlatform = (platform: PlatformItemType) => {
  paymentsStore.withdrawalGame =
    platform.title === "BGMI" ? "bgmi" : platform.value;
  selectedPlatform.value = platform;
};

const currentPlatform = computed(() => {
  const currentGame = platform.ALL_PLATFORMS_DATA[paymentsStore.withdrawalGame];
  if (currentGame) {
    return {
      title: currentGame.title,
      value: currentGame.code,
    };
  }
  return {
    title: platform.title,
    value: platform.code,
  };
});

const selectedPlatform = ref<PlatformItemType | undefined>(
  currentPlatform.value,
);
</script>
