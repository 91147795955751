import type { PlatformName } from "~/types";

export const useCaptionText = () => {
  const { t } = useI18n();
  const paymentsStore = usePaymentsStore();
  const { withdrawalGame } = storeToRefs(paymentsStore);
  const captionText: Record<PlatformName, string> = {
    standoff: t("shop.caption.standoff"),
    genshin: t("shop.caption.genshin"),
    pubg: t("shop.caption.pubg"),
    bgmi: t("shop.caption.bgmi"),
    roblox: t("shop.caption.roblox"),
    cs2: t("shop.caption.cs"),
  };
  return computed(() => {
    if (!withdrawalGame.value) return;
    return captionText[withdrawalGame.value];
  });
};
