<template>
  <Block :class="$style.block">
    <div :class="$style.wrapper">
      <div :class="$style.left">
        <Heading level="h1">{{ t("shop.choose_game") }}</Heading>
        <Caption :class="$style.caption">{{ captionText }}</Caption>
      </div>
      <slot name="tools"></slot>
    </div>
    <Separator :class="$style.separator" />
    <slot />
  </Block>
</template>

<script setup lang="ts">
import Block from "@/components/Balance/Block.vue";
import Caption from "@/components/Balance/Caption.vue";
import Heading from "@/components/Balance/Heading.vue";
import Separator from "@/components/Balance/Separator.vue";
import { useCaptionText } from "./composable/useCaptionText";

const captionText = useCaptionText();
const { t } = useI18n();
</script>

<style lang="scss" module>
.block {
  width: 100%;
  margin: 0 auto 0;
  position: relative;
}

.wrapper {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.caption {
  margin-top: 12px;
  margin-bottom: 12px;
}

.separator {
  margin-bottom: 20px;
}

.footer {
  margin-top: 8px;
}
</style>
